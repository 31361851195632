<template>
  <svg
    width="25px"
    height="25px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 397.215 397.215"
    style="enable-background: new 0 0 397.215 397.215"
    xml:space="preserve"
  >
    <g>
      <path
        d="M346.303,78.039h-194.07V65.725c0-10.346-8.416-18.764-18.761-18.764H18.761C8.416,46.961,0,55.379,0,65.725v233.621
			c0,28.072,22.84,50.908,50.914,50.908h295.389c28.072,0,50.912-22.836,50.912-50.908V128.947
			C397.215,100.873,374.377,78.039,346.303,78.039z M127.568,71.625v18.746c0,6.811,5.522,12.332,12.333,12.332h206.402
			c11.846,0,21.766,7.938,25.018,18.734H24.665V71.625H127.568z M346.303,325.588H50.914c-14.473,0-26.249-11.773-26.249-26.242
			V146.103h347.884v153.243C372.549,313.814,360.775,325.588,346.303,325.588z"
      />
      <path
        d="M129.203,235.074h-24.668V210.41c0-6.811-5.521-12.334-12.333-12.334c-6.811,0-12.333,5.524-12.333,12.334v24.664H55.201
			c-6.811,0-12.333,5.521-12.333,12.332s5.522,12.334,12.333,12.334h24.668v24.67c0,6.811,5.522,12.334,12.333,12.334
			s12.333-5.523,12.333-12.334v-24.67h24.668c6.811,0,12.333-5.521,12.333-12.334C141.536,240.595,136.014,235.074,129.203,235.074z
			"
      />
    </g>
  </svg>
</template>
